import React from "react"
import Layout from '../components/layout';
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby';
import styles from '../components/index.module.css'



export default function CategoryPage({ data, path }){

  const { prismicProduct, site } = data;
  const siteUrl = site.siteMetadata.siteUrl
  const { metadescription,
          title,
          productname,
          introtext,
          maintext,
          productimage,
          summary,
          disclaimertext
        } = prismicProduct.data

  return (
    <Layout>
    <Helmet>
    <meta name="description" content={metadescription} />
    <title> {title} </title>
    <link rel="canonical" href={siteUrl + path} />
    </Helmet>

    <div className={styles.productCategoryMainContainer}>
      <div className={ styles.productCategoryMainDescription }>
        <div className={ styles.productCategoryMainDescriptionHeader }>
          <div dangerouslySetInnerHTML={{__html: productname.html}}/>
        </div>
          <div dangerouslySetInnerHTML={{__html: introtext.html}}/>
          {productimage ? <img src={productimage.url} alt={productimage.alt}/> : null}
          {productimage.alt !== null &&
            <p className={styles.imageDescription}>{productimage.alt}</p>
          }
          <div dangerouslySetInnerHTML={{__html: maintext.html}}/>
          <h3>Sammanfattning</h3>
          <div dangerouslySetInnerHTML={{__html: summary.html}}/>
                {disclaimertext && <div className={styles.sponsor} dangerouslySetInnerHTML={{__html: disclaimertext}}/>}
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query products($id: String!) {
  site {
    siteMetadata {
      siteUrl
    }
  }
  prismicProduct(id: {eq: $id}) {
    data {
      title
      metadescription
      disclaimertext
      introtext {
        html
        text
        raw
      }
      maintext {
        html
        text
        raw
      }
      parentcategory {
        id
      }
      productimage {
        alt
        copyright
        url
      }
      productname {
        html
        text
        raw
      }
      similarproducts {
        id
      }
      summary {
        html
        text
        raw
      }
    }
  }
}
`
